<template>
  <div>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="paymentlist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#" width="80px"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column
        prop="contact_type"
        :label="$t('yh.lxfs')"
      ></el-table-column>
      <el-table-column prop="name" :label="$t('yh.lxxm')"></el-table-column>
      <el-table-column prop="phone" :label="$t('yh.lxdh')"></el-table-column>
      <el-table-column prop="contact_info" :label="$t('yh.lxxx')" align="left">
        <template slot-scope="{ row }">
          <div
            v-for="(i, index) in JSON.parse(row.contact_info)"
            :key="index"
            style="text-align:left"
          >
            {{ i.field }}:{{ i.value }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('caozuo')">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 编辑 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" label-width="180px">
        <el-form-item :label="$t('yh.lxmc')">
          <el-input v-model="editform.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('yh.lxdh')">
          <el-input v-model="editform.phone"></el-input>
        </el-form-item>
        <el-form-item :label="$t('yh.lxlx')">
          <el-select
            v-model="editform.contact_type_id"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in payment"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('yh.lxxx')">
          <el-input
            :placeholder="$t('qsr')"
            v-for="(item, index) in editform.contact_info"
            :key="index"
            v-model="item.value"
          >
            <template slot="prepend">{{ item.field }}</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1
      },
      paymentlist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      payment: [],
      editform: {}
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/user/contact', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.paymentlist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 编辑联系方式
    async edit(id) {
      this.editdialogvisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/user/editcontact/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.editform = data.data.info
          this.payment = data.data.payment
        } else {
          this.$message.error('请求失败')
        }
      }
      loading.close()
    },
    async editsubmit() {
      const { data } = await this.$http.post('/admin/user/editcontact', {
        id: this.editid,
        name: this.editform.name,
        phone: this.editform.phone,
        contact_type_id: this.editform.contact_type_id,
        contact_info: this.editform.contact_info
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(this.getlang(data.msg))
          this.editclose()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      this.editdialogvisible = false
    },
    editclose() {
      this.$refs.form.resetFields()
      this.editdialogvisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont {
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
